<template>
	<div class="grid_table manage_accounts">
		<div class="item_row heading">
			<div class="ava"></div>
			<div class="name">
				<span>Name</span>
				<div class="sort">
					<div class="asc" @click="setOrderBy('name', 'asc')" :class="{active: orderBy.sort === 'name' && orderBy.order === 'asc'}"></div>
					<div class="desc" @click="setOrderBy('name', 'desc')" :class="{active: orderBy.sort === 'name' && orderBy.order === 'desc'}"></div>
				</div>
			</div>
			<div class="type">
				<span>Type</span>
				<div class="sort">
					<div class="asc" @click="setOrderBy('type', 'asc')" :class="{active: orderBy.sort === 'type' && orderBy.order === 'asc'}"></div>
					<div class="desc" @click="setOrderBy('type', 'desc')" :class="{active: orderBy.sort === 'type' && orderBy.order === 'desc'}"></div>
				</div>
			</div>
			<div class="status">
				<span>Status</span>
				<div class="sort">
					<div class="asc disabled" :class="{active: orderBy.sort === 'subscription' && orderBy.order === 'asc'}"></div>
					<div class="desc disabled" :class="{active: orderBy.sort === 'subscription' && orderBy.order === 'desc'}"></div>
				</div>
			</div>
			<div class="followers align_right">
				<span>Followers</span>
				<div class="sort">
					<div class="asc disabled" :class="{active: orderBy.sort === 'followers' && orderBy.order === 'asc'}"></div>
					<div class="desc disabled" :class="{active: orderBy.sort === 'followers' && orderBy.order === 'desc'}"></div>
				</div>
			</div>
			<div class="revenu align_right">
				<span>Revenue</span>
				<div class="sort">
					<div class="asc disabled" :class="{active: orderBy.sort === 'revenu' && orderBy.order === 'asc'}"></div>
					<div class="desc disabled" :class="{active: orderBy.sort === 'revenu' && orderBy.order === 'desc'}"></div>
				</div>
			</div>
			<div class="more_actions"></div>
		</div><!-- end item_row heading -->

		<template v-if="!data.initialized">
			<v-skeleton-loader
				type="image"
				class="table_item_row"
				v-for="index in 30"
				:key="index"
			></v-skeleton-loader>
		</template>

		<template v-if="data.initialized && data.hasPageItems()">
			<div class="item_row data" v-for="item of data.items" :key="'A' + item.identifier">
				<div class="ava">
          <img v-if="item.avatar && item.avatar.thumbnail"
               :src="`${item.avatar.thumbnail['50x50']}?cache=${cacheKey}`"
               v-bind:srcset="`${item.avatar.thumbnail['50x50']}?cache=${cacheKey} 1x, ${item.avatar.thumbnail['50x50@2x']}?cache=${cacheKey} 2x`"
               alt="photo">
					<div class="default_ava" v-else></div>
				</div>
				<div class="name">
					<div class="artist_name" @click="redirectToAccount(item, false)">
						{{ item.name }}
					</div>
				</div>
				<div class="type">
                    {{ item.type }}
				</div>
				<div class="status">
					<router-link v-if="item.hasActiveSubscription()" :to="{name: 'subscriptions'}">Active</router-link>
					<div class="type expired" @click="showAccountIssueDialog(item)" v-if="!item.hasActiveSubscription()">
						Expired
					</div>
				</div>
				<div class="followers align_right">
                    {{item.followersCount}}
				</div>
				<div class="revenu align_right">-</div>
				<div class="more">
					<AccountActions :account="item" @delete="deleteAccount" @redirect="redirectToAccount"></AccountActions>
				</div>
			</div>

			<pagination v-if="data.paginate"
				:count="data.total"
				:currentPage="data.page"
				:commit="'manageAccounts/SET_CURRENT_PAGE'"
			></pagination>
		</template>

		<template v-if="data.initialized && !data.hasPageItems()">
			<nothingHereYet></nothingHereYet>
		</template>

            <!--  tmp disabled  -->
<!--		<deleteAccount v-if="prepareDeleteAccount" v-model="prepareDeleteAccount" :delete-account="prepareDeleteAccount" :account-releases="artistReleases"></deleteAccount>-->
        <IssueAccountSubscription v-if="prepareIssueAccount" v-model="prepareIssueAccount" @resolve="paymentIssueAccount"></IssueAccountSubscription>

        <paymentStripeModal
            v-if="issueAccount"
            v-model="issueAccount"
            :clientSecret="clientSecret"
            :paymentPerson="issueAccount" >
        </paymentStripeModal>

<!--        <newAccount v-if="prepareRestrictedAccount" v-model="prepareRestrictedAccount" :first-show-modal="'account-issue'" :uses="'itemAccount'" :restricted-account="prepareRestrictedAccount"></newAccount>-->
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

// components
import pagination from "@/components/pagination";
import nothingHereYet from "@/components/small/nothingHereYet";
import AccountActions from "@/components/manage/accounts/_actions";
import IssueAccountSubscription from "@/components/dialogs/issueAccountSubscription";

// models
import PaginationM from "@/models/paginationM";
import type Account from "@/models/accountM";
import paymentStripeModal from "@/components/dialogs/paymentStripeModal.vue";
import SubscriptionManager from "@/services/subscriptionManager";

export default {
    name: "ManageAccountsTable",
    props: {
        data: PaginationM,
        typeAccounts: String
    },
    components: {
        paymentStripeModal,
        AccountActions,
        nothingHereYet,
        pagination,
        IssueAccountSubscription
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            prepareDeleteAccount: null,
            artistReleases: null,
            issueAccount: null,
            prepareIssueAccount: null,
            clientSecret: null,
            cacheKey: new Date().getTime(),

            // services
            manager: new SubscriptionManager(),
        }
    },
    computed: {
        ...mapGetters({
            orderBy: 'manageAccounts/ORDER_BY',
            currentPage: 'manageAccounts/CURRENT_PAGE',
        }),
    },
    methods: {
        ...mapActions({
            getArtistReleases: 'GET_ARTIST_ALBUMS'
        }),

        setOrderBy(key: string, direction: string): void {
            this.$store.commit('manageAccounts/SET_ORDER_BY', {
                sort: key,
                order: direction
            });
        },

        showAccountIssueDialog(account: Account): void {
            this.issueAccount = null;
            this.prepareIssueAccount = account;
        },

        deleteAccount(account: Account): void {
            this.prepareDeleteAccount = account;
            this.getArtistReleases({artist: this.prepareDeleteAccount.id})
                .catch(err => console.error(`deleteArtistAccount - getArtistReleases, ${err}`))
        },

        redirectToAccount(account: Account, edit: boolean): void {
            let routeName = (account.type.toLowerCase() === 'artist') ? 'artist-profile' : 'label-profile'
            this.$router.push({name: routeName, params: {id: account.id, name: window.toHumanFormat(account.identifier), edit: edit}});
        },

        paymentIssueAccount(account: Account): void {
            this.manager.renew(account)
                .then((clientSecret) => {
                    this.clientSecret = clientSecret
                })
                .finally(() => {
                    account.changeBusy(false);
                    this.prepareIssueAccount = null;

                    if (account.hasCanceledSubscription() && !account.subscription.currentPeriodIsEnded()) {
                        account.subscription.status = 'active';
                        account.subscription.issue = {};
                        return;
                    }

                    this.issueAccount = account;
                });
        }
    }
}
</script>

<style scoped>
</style>
