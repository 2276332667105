<template>
    <v-menu
        transition="slide-y-transition"
        :close-on-content-click="true"
        :nudge-width="70"
        offset-y
        nudge-left="40"
        nudge-bottom="10"
        origin="top center"
        content-class="br20 accounts_modal">
        <template v-slot:activator="{ on, attrs }">
            <button type="button" v-ripple class="more_menu" v-bind="attrs" v-on="on"></button>
        </template>
        <v-list>
            <v-list-item v-ripple>
                <template v-if="isAdmin">
                    <button @click="$emit('redirect', account, true)">Edit</button>
                </template>
                <template v-else>
                    <button type="button" disabled>Edit</button>
                </template>
            </v-list-item>

<!--            <v-list-item v-ripple>-->
<!--                <template v-if="isAdmin">-->
<!--                    <button type="button" @click="$emit('delete', account)" class="delete">Delete</button>-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                    <button type="button" disabled>Delete</button>-->
<!--                </template>-->
<!--            </v-list-item>-->
        </v-list>
    </v-menu>
</template>

<script>
    import {mapGetters} from "vuex";
    import AccountM from "@/models/accountM";

    export default {
        name: "AccountActions",
        props: {
            account: AccountM
        },
        computed: {
            ...mapGetters({
                isAdmin: 'isAdmin',
            }),
        },
    }
</script>

<style scoped>
</style>
