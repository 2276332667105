<template>
    <v-dialog :value="value" @input="$emit('input')" :content-class="'create_new_account_modal'" max-width="550">
        <div class="heading">
            <h2 class="heading_new_account">
                Account Issue
            </h2>
        </div>

        <div class="wrapper_content h165">
            <div class="account_issue_box" v-if="!value.subscription.isExpires()">
                Your subscription for <span>{{ value.name }}</span> has expired. Please renew your subscription to resolve the issue.
            </div>
            <div class="account_issue_box" v-else>
                Please renew your account to continue.
            </div>
        </div>

        <div class="wrapper_button center">
            <button type="button" class="tetriatary_btn" v-ripple @click="dialog = false" >Cancel</button>
            <button type="button" class="tetriatary_btn" v-ripple @click="resolve" :class="{loading: loading}">
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
                Renew
            </button>
        </div>
    </v-dialog>
</template>

<script>
import AccountM from "@/models/accountM";

export default {
    name: "IssueAccountSubscription",
    props: {
        value: AccountM
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        resolve() {
            this.loading = true;
            this.$emit('resolve', this.value);
        }
    }
}
</script>

<style lang="scss">

</style>
